@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    background-color: #101827;
}

button, div {
  -webkit-tap-highlight-color: transparent;
}

.text-supersmall {
  font-size: 0.55rem;
}

.drone-icon path {
  fill: rgba(135, 135, 135, 0.33);
}

.drone-icon.good-to-fly path {
  fill: rgba(0, 142, 26, 1);
}

.drone-icon.fly-with-caution path {
  fill: rgba(195, 166, 3, 0.648);
}

.drone-icon.notgood-to-fly path {
  fill: rgb(215 74 66)
}

.time-icon path {
  fill: #475569;
}

.arrow-icon path {
  fill: rgba(255, 255, 255, 0.4);
}

.logged-name {
  max-width: 200px;;
}

.weather-row-hidden,
.weather-cell,
.clear-button,
.highlight,
.map-conatainer {
  animation: fadein 0.25s ease-in;
}


.zoom-anime {
  animation: zoomin 0.2s ease-in-out;
}

.width-anime {
  transition: width .5s ease-in-out;
}

.map-background {
  background-image: url('./assets/images/map.jpg');
  background-size: auto;
  background-blend-mode: overlay;
}

/* -------------------------------------------------- */
/* ---------------- Animation part ------------------ */
/* -------------------------------------------------- */

@keyframes fadein {
  0% {
      opacity: 0;
  }

  100% {
      opacity: 1;
  }
}

@keyframes zoomin {
  0% {
      opacity: 0;
      transform: scale(2.5, 2.5);
  }

  100% {
      opacity: 1;
      transform: scale(1, 1);
  }
}